/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module {
  height: calc(100vh - 60px);
}
.Module .module-header {
  background-color: #272e34;
  min-height: 62px;
  clear: both;
}
.Module .module-header .wrap {
  display: inline-flex;
  max-width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
}
.Module .module-header .module-header-info {
  height: 62px;
}
.Module .module-header .module-header-info .module-header-banner {
  font-size: x-large;
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title {
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title i,
.Module .module-header .module-header-info .module-header-title a {
  color: #0df;
}
.Module .module-header .module-header-info .module-header-title a {
  font-size: 1rem;
}
.Module .module-header .module-header-info .module-header-title i {
  transform: scale(0.9);
}
.Module .module-header .header-selected {
  border-radius: 0;
}
.Module .module-header .view-exit {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}
.Module .module-header .view-title {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.Module .module-header .view-title .material-icons {
  color: #fff;
  font-size: 24px;
  padding-left: 20px;
}
.Module .module-header .header-bar {
  float: left;
  margin-left: 10px;
}
.Module .module-header .header-bar a .btn {
  margin-right: 10px;
}
.Module .module-header .header-bar .form-inline {
  float: left;
  padding-top: 11px;
  font-size: 1rem;
}
.Module .module-header .header-bar .form-inline .input-group {
  margin-right: 20px;
}
.Module .module-header .header-bar .form-inline input {
  display: inline-block;
}
.Module .module-header .header-bar .form-inline label {
  padding-right: 5px;
  text-transform: capitalize;
}
.Module .module-header .header-bar .form-inline ::placeholder {
  color: #dae0e7;
}
.Module .module-header .secondary-header-toolbar {
  float: right;
  margin-right: 10px;
}
.Module .module-header .header-menu {
  background-color: #272e34;
}
.Module .module-header .header-selector {
  padding: 10px;
}
.Module .module-header .header-selector label {
  padding-top: 10px;
  padding-right: 10px;
  margin: 0;
}
.Module .module-header .header-selector .material-icons {
  padding-top: 5px;
}
.Module .module-header .header-selector .dropdown-menu-dark button {
  color: #aaaeb3;
  background-color: #272e34;
}
.Module .module-header .header-disabled {
  visibility: hidden;
}
.Module .module-header .btn-toolbar .header-button-group .material-icons {
  position: relative;
  top: 2px;
  color: #aaaeb3;
  padding-right: 5px;
  font-size: 16px;
}
.Module .module-header .btn-toolbar .header-button-group .selected .material-icons {
  color: #4bb3d8;
}
.Module .module-header .btn-toolbar .btn-group {
  margin-right: 10px;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary {
  outline: none;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary:focus {
  outline: none;
  box-shadow: none;
}
.Module .module-header .btn-toolbar .btn-group .btn {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-header .btn-toolbar .date-range-group .selected .material-icons {
  color: #fff;
}
.Module .module-content {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #272e34;
  height: calc(100% - 62px);
}
.Module .module-content .header-id {
  font-size: small;
  color: #aaaeb3;
  padding-left: 10px;
}
.Module .module-content .content-view {
  height: 100%;
}
.Module .module-content .force {
  position: fixed;
  bottom: 0;
  margin-right: 20px;
  width: calc(100vw - 190px - 10px);
}
.Module .module-content .content-view-controls {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  padding-left: 0;
  height: 50px;
  background-color: #272e34;
}
.Module .module-content .content-view-controls a .btn:last-child {
  margin-right: 10px;
}
.Module .module-content .content-view-controls .header-button-group .btn {
  margin: 0;
}
.Module .module-content .content-view-controls label {
  margin-top: 5px;
  margin-right: 5px;
}
.Module .module-content .content-view-controls form {
  margin-right: 10px;
}
.Module .module-content .content-view-controls form .form-check-label-color {
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-top: 0;
}
.Module .module-content .content-view-controls .form-check-inline {
  margin-right: 0;
}
.Module .module-content .search-bar {
  position: fixed;
  width: calc(100% - 190px);
  padding-right: 20px;
}
.Module .module-content .search-bar .input-group {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group .input-group-text {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .search-bar .input-group button,
.Module .module-content .search-bar .input-group input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
}
.Module .module-content .search-bar .input-group-sm {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group-sm .input-group-text {
  background-color: #272e34;
  color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .input-group-sm button,
.Module .module-content .search-bar .input-group-sm input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .form-check-label {
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.Module .module-content .content {
  float: left;
  width: calc(100% - 330px - 10px - 2px);
  height: 100%;
  background-color: #353c44;
}
.Module .module-content .content .content-with-title {
  height: 100%;
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main {
  height: calc(100% - 300px);
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main-full {
  height: 100%;
}
.Module .module-content .content .content-footer {
  height: 300px;
  width: 100%;
}
.Module .module-content .content .ok {
  background-color: #4caf50;
}
.Module .module-content .content .warning {
  background-color: orange;
}
.Module .module-content .content .error {
  background-color: #e22006;
}
.Module .module-content .content .no-status {
  background-color: #272e34;
}
.Module .module-content .content .no-match {
  text-align: center;
}
.Module .module-content .content .content-title {
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.Module .module-content .content .content-subtitle {
  text-align: center;
  padding: 10px;
  margin: 0;
}
.Module .module-content .content .site-content {
  padding-bottom: 50px;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.ok {
  color: #4caf50;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.warning {
  color: orange;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.error {
  color: #e22006;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.no-status {
  color: #fff;
}
.Module .module-content .content .site-content td.ranged {
  background-color: #272e34;
}
.Module .module-content .content .system-content {
  clear: both;
}
.Module .module-content .content table {
  width: 100%;
  border-collapse: separate;
  /* Don't collapse as this makes sticky headers see through */
  border-spacing: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content table th {
  top: 0;
  border-bottom: 2px solid #272e34;
  background-color: #353c44;
  z-index: 1;
  color: #4bb3d8;
  text-align: left;
}
.Module .module-content .content table th.ranged {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content table td {
  border: solid 1px #272e34;
}
.Module .module-content .content table td a {
  color: #fff;
}
.Module .module-content .content table td .btn-link {
  padding: 0;
  padding-left: 5px;
}
.Module .module-content .content table td .btn-link .material-icons {
  color: #4bb3d8;
}
.Module .module-content .content table td.danger {
  background-color: #e22006;
}
.Module .module-content .content table td.ranged {
  background-color: #353c44;
}
.Module .module-content .content table th.edit {
  width: 20px;
}
.Module .module-content .content .content-map {
  height: 100%;
  width: 100%;
}
.Module .module-content .content nav {
  background-color: #272e34;
  z-index: 100;
  color: #fff;
}
.Module .module-content .content nav .react-contextmenu-item {
  color: #fff;
}
.Module .module-content .content nav .ok > .react-contextmenu-item {
  background-color: #4caf50;
}
.Module .module-content .content nav .warning > .react-contextmenu-item {
  background-color: orange;
}
.Module .module-content .content nav .error > .react-contextmenu-item {
  background-color: #e22006;
}
.Module .module-content .content nav .no-nodes > .react-contextmenu-item {
  padding: 0 10px;
}
.Module .module-content .content .content-graph-menu {
  text-align: center;
  background-color: #272e34;
  clear: both;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-left {
  font-weight: bold;
  float: left;
  margin-left: 10px;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-right {
  float: right;
  margin-right: 10px;
}
.Module .module-content .content .content-graph-menu .dropdown {
  display: inline;
  z-index: 10;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-menu {
  background-color: #1f1f1f;
}
.Module .module-content .content .content-graph-menu .dropdown button {
  color: #fff;
  background-color: #272e34;
  border: none;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-item:hover {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-toggle {
  color: #fff;
  box-shadow: none;
}
.Module .module-content .content .content-graph-menu .dropdown .selected {
  color: #4bb3d8;
}
.Module .module-content .content .content-graph-empty {
  text-align: center;
  font-size: large;
  padding-top: 100px;
}
.Module .module-content .content .content-graph,
.Module .module-content .content .content-source-graph {
  width: 100%;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper {
  pointer-events: auto;
  margin-left: -5px;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip {
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list {
  max-height: 165px;
}
.Module .module-content .content .content-graph {
  height: calc(300px - 30px);
}
.Module .module-content .content .content-source-graph {
  height: calc(300px + 230px);
}
.Module .module-content .content-full {
  width: 100%;
  overflow-y: auto;
}
.Module .module-content .content-full-with-title {
  width: 100%;
  height: calc(100% - 66px);
  overflow-y: auto;
}
.Module .module-content .close-button {
  top: 0;
  font-size: 48px;
}
.Module .module-content .item-details {
  height: 100%;
}
.Module .module-content .item-details .item-details-title {
  padding: 10px 20px;
  font-size: large;
}
.Module .module-content .item-details .item-details-title h2 {
  padding: 0;
}
.Module .module-content .item-details .item-details-title h2 .right-title {
  float: right;
  font-size: medium;
  padding-top: 14px;
}
.Module .module-content .item-details .item-create-title {
  background-color: #4bb3d8;
}
.Module .module-content .item-details .item-details-content {
  overflow: auto;
  height: calc(100% - 66px);
  background-color: #272e34;
  padding: 20px;
}
.Module .module-content .item-details .item-details-content label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .item-details .item-details-content .ecogy-form label {
  margin-right: 0;
}
.Module .module-content .item-details .item-details-content .type {
  text-transform: capitalize;
}
.Module .module-content .item-details .item-details-content hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.Module .module-content .item-details .item-details-content .remove-button {
  margin-bottom: 20px;
}
.Module .module-content .item-details .item-details-content form > .form-group fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content form > .form-group > div > div > fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content .right-column {
  float: right;
  clear: both;
  padding-right: 0;
  padding-left: 10px;
}
.Module .module-content .content-sidebar {
  margin-left: 10px;
  float: left;
  width: 330px;
  height: 100%;
  overflow: auto;
}
.Module .module-content .content-sidebar .sidebar-section {
  margin-top: 10px;
  background-color: #353c44;
  width: 100%;
  padding: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-subtitle {
  color: #aaaeb3;
  float: right;
  margin: 0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-range {
  color: #aaaeb3;
  margin: 0;
  font-size: small;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content {
  width: 100%;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-heading {
  font-size: xx-large;
  font-weight: bold;
  color: #4bb3d8;
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .positive {
  color: #00c4a0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .negative {
  color: #ff9b91;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text {
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text .material-icons {
  float: right;
  padding-top: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-reading {
  color: #4bb3d8;
  font-size: x-large;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .warning {
  color: orange;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-error {
  color: #4bb3d8;
  font-size: large;
  text-align: center;
  padding: 10px 0;
}
.Module .module-content .content-sidebar :first-child {
  margin: 0;
}
.Module .module-content .content-sidebar .date-range {
  color: #aaaeb3;
}
.Module .module-content .content-sidebar .date-range .start-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .end-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .date-label {
  width: 50px;
}
.Module .module-content .recharts-tooltip-cursor {
  fill-opacity: 25%;
}
.Module .module-content .content-with-controls {
  height: calc(100% - 50px);
}
.Module .collapsed {
  height: calc(100% - 62px - 41px);
}

.App-portfolio .module-header {
  background-color: #4bb3d8;
  min-height: 61px;
  border-bottom: 1px solid black;
}
.App-portfolio .module-content {
  margin-top: 10px;
  height: calc(100% - 62px - 10px);
}
.App-portfolio .collapsed {
  height: calc(100% - 62px - 41px - 10px);
}

.area-graph-tooltip {
  background: #fff;
  padding: 10px;
  color: #272e34;
  text-transform: capitalize;
}
.area-graph-tooltip p {
  margin: 0;
}

.econode-tooltip .area-graph-tooltip,
.ecogy-tooltip .area-graph-tooltip {
  text-transform: none;
}

.modal {
  color: #000;
}
.modal .error {
  color: #e22006;
}

.custom-range-button {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-range-name > * {
  float: left;
}
.custom-range-name .range .range-start {
  font-size: small;
}
.custom-range-name .range .range-end {
  font-size: small;
}
.custom-range-name .range .range-divider {
  display: none;
}

.date-range-menu {
  width: 700px;
  height: 370px;
  left: auto;
  right: 0;
}
.date-range-menu .custom-date-range {
  float: left;
  width: 510px;
}
.date-range-menu .custom-date-range .custom-date {
  float: left;
  width: 250px;
  margin-left: 5px;
  height: 310px;
}
.date-range-menu .precanned-date-ranges {
  float: left;
  margin-left: 10px;
  height: 100%;
  width: 170px;
  background-color: #353c44;
  border-radius: 5px;
  padding: 10px;
}
.date-range-menu .precanned-date-ranges .dropdown-item {
  padding: 0.25rem;
  border: none;
}
.date-range-menu .precanned-date-ranges .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.date-range-menu .date-range-buttons {
  clear: both;
}
.date-range-menu .date-range-buttons .date-range-button {
  border-radius: 10px;
  float: right;
}
.date-range-menu .date-range-message {
  width: 100%;
  color: #4bb3d8;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-message .material-icons {
  color: #4bb3d8;
}
.date-range-menu .date-range-warning {
  width: 100%;
  color: #e22006;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-warning .material-icons {
  color: #e22006;
}

.battery-view-header {
  text-align: center;
}

.battery-box {
  height: 150px;
  width: 200px;
  background-color: rgb(29, 33, 39);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75px 75px;
  color: grey;
}

.battery-container {
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 350px;
  width: 250px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 80px 230px;
  grid-template-rows: 80px 150px 80px;
  color: rgb(207, 205, 205);
}
.battery-container p {
  font-size: medium;
}
.battery-container .state-of-health {
  margin-top: 8px;
  margin-bottom: 40px;
  background-color: #193138;
  border-radius: 5px;
  font-size: small;
}

.graph-tail-end {
  height: 8px;
  width: 8px;
  background-color: #00C49F;
  bottom: 180px;
  left: 0px;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.battery-cell {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #1c3841;
  height: 100px;
  width: 70px;
  position: relative;
}
.battery-cell .logo {
  position: relative;
  top: 40px;
  left: -14px;
  max-width: 100px;
  filter: opacity(0.6) drop-shadow(0 0 0 rgb(0, 0, 0));
  transform: rotate(270deg);
}
.battery-cell .charge-bar {
  margin: 5px;
  position: absolute;
  width: 60px;
  bottom: 0px;
  background-color: #4caf50;
}
.battery-cell .empty {
  visibility: hidden;
}
.battery-cell .low {
  background-color: #e22006;
}
.battery-cell .high {
  background-color: #4caf50;
}
.battery-cell .charge-bar-wrapper {
  position: absolute;
  height: 90px;
  width: 60px;
  bottom: 0px;
}
.battery-cell .charge-bar-top {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  top: -10px;
  box-shadow: inset 0px -6px 12px 0px hsla(0, 0%, 0%, 0.2);
}
.battery-cell .charge-bar-bottom {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  bottom: -10px;
}
.battery-cell .battery-cell-top {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -10px;
  background-color: #193138;
  z-index: 100;
}
.battery-cell .battery-cell-top2 {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -5px;
  background-color: #1c3841;
  z-index: 99;
}
.battery-cell .battery-cell-bottom {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  bottom: -10px;
  background-color: #1c3841;
}

[class|=confetti] {
  position: absolute;
  z-index: 3000;
}

.papers-origin {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
}

.confetti-0 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(294.0457358185deg);
  animation: drop-0 3.8373995344s 0.5900065379s 3;
}

@keyframes drop-0 {
  25% {
    top: -13vh;
    left: 10vw;
    opacity: 0.8195510051;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-1 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(211.5207774999deg);
  animation: drop-1 3.2971771273s 0.4354240545s 3;
}

@keyframes drop-1 {
  25% {
    top: -16vh;
    left: 5vw;
    opacity: 1.2194218069;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-2 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(59.0193294478deg);
  animation: drop-2 3.0788263493s 0.4821255536s 3;
}

@keyframes drop-2 {
  25% {
    top: -22vh;
    left: 13vw;
    opacity: 0.6478731762;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-3 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(340.9889357678deg);
  animation: drop-3 3.2070195195s 0.9928016927s 3;
}

@keyframes drop-3 {
  25% {
    top: -6vh;
    left: 3vw;
    opacity: 0.7125490428;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-4 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(190.5205489939deg);
  animation: drop-4 3.8635119484s 0.8304092357s 3;
}

@keyframes drop-4 {
  25% {
    top: -1vh;
    left: 8vw;
    opacity: 1.3010238448;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-5 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(111.016767347deg);
  animation: drop-5 3.4022190254s 0.7269263948s 3;
}

@keyframes drop-5 {
  25% {
    top: -24vh;
    left: 15vw;
    opacity: 0.8251587581;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-6 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(340.745768623deg);
  animation: drop-6 3.9997426673s 0.6550325947s 3;
}

@keyframes drop-6 {
  25% {
    top: -11vh;
    left: 14vw;
    opacity: 0.775815826;
  }
  100% {
    top: 50vh;
    left: 20vw;
    opacity: 0;
  }
}
.confetti-7 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(217.7125781211deg);
  animation: drop-7 3.1161562355s 0.3735940274s 3;
}

@keyframes drop-7 {
  25% {
    top: -21vh;
    left: -5vw;
    opacity: 1.3470722763;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-8 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(286.1794705302deg);
  animation: drop-8 3.0284785524s 0.173384175s 3;
}

@keyframes drop-8 {
  25% {
    top: -23vh;
    left: 0vw;
    opacity: 0.6491163635;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-9 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(55.1922291802deg);
  animation: drop-9 3.7024365675s 0.6993434615s 3;
}

@keyframes drop-9 {
  25% {
    top: -5vh;
    left: -1vw;
    opacity: 0.7898240183;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-10 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(284.7241175157deg);
  animation: drop-10 3.8333135641s 0.1169664873s 3;
}

@keyframes drop-10 {
  25% {
    top: -25vh;
    left: -2vw;
    opacity: 0.8734481107;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-11 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(220.1974446579deg);
  animation: drop-11 3.7747369451s 0.6494687779s 3;
}

@keyframes drop-11 {
  25% {
    top: -25vh;
    left: -7vw;
    opacity: 1.3779924115;
  }
  100% {
    top: 50vh;
    left: -18vw;
    opacity: 0;
  }
}
.confetti-12 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(24.0528175555deg);
  animation: drop-12 3.6957561084s 0.9141183239s 3;
}

@keyframes drop-12 {
  25% {
    top: -18vh;
    left: -2vw;
    opacity: 0.6506098484;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-13 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(69.482576939deg);
  animation: drop-13 3.614618272s 0.3241395945s 3;
}

@keyframes drop-13 {
  25% {
    top: -17vh;
    left: 9vw;
    opacity: 0.8380712505;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-14 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(154.4028938004deg);
  animation: drop-14 3.3825352776s 0.1555495467s 3;
}

@keyframes drop-14 {
  25% {
    top: -25vh;
    left: 6vw;
    opacity: 1.3158338725;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-15 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(63.8513124492deg);
  animation: drop-15 3.4743690229s 0.360966448s 3;
}

@keyframes drop-15 {
  25% {
    top: -12vh;
    left: 0vw;
    opacity: 1.0309899466;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-16 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(165.9253057851deg);
  animation: drop-16 3.2172245791s 0.6023870522s 3;
}

@keyframes drop-16 {
  25% {
    top: -16vh;
    left: 1vw;
    opacity: 0.9955835794;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-17 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(291.9451057839deg);
  animation: drop-17 3.2115360123s 0.9346065628s 3;
}

@keyframes drop-17 {
  25% {
    top: -25vh;
    left: -3vw;
    opacity: 1.439537325;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-18 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(102.7769818712deg);
  animation: drop-18 3.7368145602s 0.8858680833s 3;
}

@keyframes drop-18 {
  25% {
    top: -3vh;
    left: -6vw;
    opacity: 0.5655751589;
  }
  100% {
    top: 50vh;
    left: -12vw;
    opacity: 0;
  }
}
.confetti-19 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(106.6550079552deg);
  animation: drop-19 3.6439122082s 0.9455700056s 3;
}

@keyframes drop-19 {
  25% {
    top: -4vh;
    left: -3vw;
    opacity: 1.3901329801;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-20 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(66.1389021186deg);
  animation: drop-20 3.5712419926s 0.572134636s 3;
}

@keyframes drop-20 {
  25% {
    top: -2vh;
    left: 9vw;
    opacity: 1.419741412;
  }
  100% {
    top: 50vh;
    left: 24vw;
    opacity: 0;
  }
}
.confetti-21 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(40.4115402513deg);
  animation: drop-21 3.4834291426s 0.9782347218s 3;
}

@keyframes drop-21 {
  25% {
    top: -23vh;
    left: 2vw;
    opacity: 0.508739999;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-22 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(289.4284022935deg);
  animation: drop-22 3.4062977959s 0.4408595362s 3;
}

@keyframes drop-22 {
  25% {
    top: -15vh;
    left: 4vw;
    opacity: 0.8753022447;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-23 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(90.0291257407deg);
  animation: drop-23 3.8344118384s 0.6271755943s 3;
}

@keyframes drop-23 {
  25% {
    top: -10vh;
    left: -2vw;
    opacity: 1.2190968618;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-24 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(198.4805935852deg);
  animation: drop-24 3.8539155312s 0.8768009803s 3;
}

@keyframes drop-24 {
  25% {
    top: -14vh;
    left: -7vw;
    opacity: 0.8543225443;
  }
  100% {
    top: 50vh;
    left: -5vw;
    opacity: 0;
  }
}
.confetti-25 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(44.9632350815deg);
  animation: drop-25 3.228659717s 0.4871027888s 3;
}

@keyframes drop-25 {
  25% {
    top: -10vh;
    left: -11vw;
    opacity: 0.6553181854;
  }
  100% {
    top: 50vh;
    left: -15vw;
    opacity: 0;
  }
}
.confetti-26 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(255.3575963352deg);
  animation: drop-26 3.8247327674s 0.9332086483s 3;
}

@keyframes drop-26 {
  25% {
    top: -17vh;
    left: 13vw;
    opacity: 0.6079854147;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-27 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(264.5906572098deg);
  animation: drop-27 3.9619828964s 0.2750065383s 3;
}

@keyframes drop-27 {
  25% {
    top: -4vh;
    left: 9vw;
    opacity: 1.3498616506;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-28 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(252.6319718563deg);
  animation: drop-28 3.4824778068s 0.5584940048s 3;
}

@keyframes drop-28 {
  25% {
    top: -25vh;
    left: 11vw;
    opacity: 1.2535209534;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-29 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(344.8625738486deg);
  animation: drop-29 3.7861783918s 0.6257503761s 3;
}

@keyframes drop-29 {
  25% {
    top: -8vh;
    left: -2vw;
    opacity: 0.9878714385;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-30 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(153.1678052962deg);
  animation: drop-30 3.3274608592s 0.7657344319s 3;
}

@keyframes drop-30 {
  25% {
    top: -18vh;
    left: -2vw;
    opacity: 0.8140630172;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-31 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(32.5392867803deg);
  animation: drop-31 3.5738550648s 0.8376031311s 3;
}

@keyframes drop-31 {
  25% {
    top: -23vh;
    left: 10vw;
    opacity: 1.3056783566;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-32 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(13.656694589deg);
  animation: drop-32 3.6497186217s 0.7235819208s 3;
}

@keyframes drop-32 {
  25% {
    top: -11vh;
    left: 4vw;
    opacity: 0.606343501;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-33 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(252.5585358685deg);
  animation: drop-33 3.2240823677s 0.6561176987s 3;
}

@keyframes drop-33 {
  25% {
    top: -18vh;
    left: -14vw;
    opacity: 0.6500380731;
  }
  100% {
    top: 50vh;
    left: -23vw;
    opacity: 0;
  }
}
.confetti-34 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(260.5661421261deg);
  animation: drop-34 3.1332836575s 0.6840315109s 3;
}

@keyframes drop-34 {
  25% {
    top: -18vh;
    left: 5vw;
    opacity: 1.4091796816;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-35 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(233.0483511983deg);
  animation: drop-35 3.8185297782s 0.6867140364s 3;
}

@keyframes drop-35 {
  25% {
    top: -6vh;
    left: -13vw;
    opacity: 1.2656612362;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-36 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(27.8820016473deg);
  animation: drop-36 3.57205844s 0.3938240881s 3;
}

@keyframes drop-36 {
  25% {
    top: -8vh;
    left: -10vw;
    opacity: 0.7307436842;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-37 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(226.2539077756deg);
  animation: drop-37 3.4837121965s 0.2243940692s 3;
}

@keyframes drop-37 {
  25% {
    top: -10vh;
    left: -6vw;
    opacity: 1.3875887145;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-38 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(197.9027300333deg);
  animation: drop-38 3.3299407743s 0.5519049566s 3;
}

@keyframes drop-38 {
  25% {
    top: -12vh;
    left: 5vw;
    opacity: 0.857001486;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-39 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(154.1915290494deg);
  animation: drop-39 3.9085228164s 0.2576385461s 3;
}

@keyframes drop-39 {
  25% {
    top: -4vh;
    left: -6vw;
    opacity: 1.2486001051;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-40 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(8.4093726335deg);
  animation: drop-40 3.8603169644s 0.7739350944s 3;
}

@keyframes drop-40 {
  25% {
    top: -9vh;
    left: -1vw;
    opacity: 0.826474599;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-41 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(242.2913181155deg);
  animation: drop-41 3.2052906055s 0.169328058s 3;
}

@keyframes drop-41 {
  25% {
    top: -12vh;
    left: 8vw;
    opacity: 1.2125528428;
  }
  100% {
    top: 50vh;
    left: 16vw;
    opacity: 0;
  }
}
.confetti-42 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(337.2666081353deg);
  animation: drop-42 3.316405207s 0.8865368803s 3;
}

@keyframes drop-42 {
  25% {
    top: -3vh;
    left: 6vw;
    opacity: 0.5482020059;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-43 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(277.0928349586deg);
  animation: drop-43 3.5978664977s 0.9935516875s 3;
}

@keyframes drop-43 {
  25% {
    top: -18vh;
    left: -9vw;
    opacity: 1.2570396972;
  }
  100% {
    top: 50vh;
    left: -17vw;
    opacity: 0;
  }
}
.confetti-44 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(165.0878102412deg);
  animation: drop-44 3.6722722172s 0.1602728295s 3;
}

@keyframes drop-44 {
  25% {
    top: -11vh;
    left: 10vw;
    opacity: 0.9083517558;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-45 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(344.3838394028deg);
  animation: drop-45 3.6002362731s 0.5793244806s 3;
}

@keyframes drop-45 {
  25% {
    top: -10vh;
    left: 12vw;
    opacity: 0.725577917;
  }
  100% {
    top: 50vh;
    left: 26vw;
    opacity: 0;
  }
}
.confetti-46 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(273.4188587599deg);
  animation: drop-46 3.1096549276s 0.1384974704s 3;
}

@keyframes drop-46 {
  25% {
    top: -6vh;
    left: 4vw;
    opacity: 0.5053114466;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-47 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(217.9668299937deg);
  animation: drop-47 3.031551932s 0.8811883272s 3;
}

@keyframes drop-47 {
  25% {
    top: -10vh;
    left: -10vw;
    opacity: 0.6925136203;
  }
  100% {
    top: 50vh;
    left: -16vw;
    opacity: 0;
  }
}
.confetti-48 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(197.4890692201deg);
  animation: drop-48 3.6806749963s 0.774158584s 3;
}

@keyframes drop-48 {
  25% {
    top: -9vh;
    left: 6vw;
    opacity: 1.056955157;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-49 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(347.9854881175deg);
  animation: drop-49 3.0648300407s 0.7184498683s 3;
}

@keyframes drop-49 {
  25% {
    top: -17vh;
    left: 4vw;
    opacity: 0.5107290969;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-50 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(91.4674530206deg);
  animation: drop-50 3.644418095s 0.897636056s 3;
}

@keyframes drop-50 {
  25% {
    top: -25vh;
    left: 10vw;
    opacity: 1.3744559175;
  }
  100% {
    top: 50vh;
    left: 23vw;
    opacity: 0;
  }
}
.confetti-51 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(338.4489516588deg);
  animation: drop-51 3.9822630565s 0.1110537163s 3;
}

@keyframes drop-51 {
  25% {
    top: -18vh;
    left: -12vw;
    opacity: 1.2626135823;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-52 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(262.8746526689deg);
  animation: drop-52 3.331267326s 0.7714454138s 3;
}

@keyframes drop-52 {
  25% {
    top: -15vh;
    left: 7vw;
    opacity: 1.0581687245;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-53 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(195.0850724354deg);
  animation: drop-53 3.8125818964s 0.5134231866s 3;
}

@keyframes drop-53 {
  25% {
    top: -12vh;
    left: -10vw;
    opacity: 0.5955038415;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-54 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(345.0881986981deg);
  animation: drop-54 3.6788260553s 0.9830268188s 3;
}

@keyframes drop-54 {
  25% {
    top: -21vh;
    left: 4vw;
    opacity: 0.7519836494;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-55 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(290.8335276655deg);
  animation: drop-55 3.8546099434s 0.1791397602s 3;
}

@keyframes drop-55 {
  25% {
    top: -1vh;
    left: 8vw;
    opacity: 1.1990789236;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-56 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(301.9545595511deg);
  animation: drop-56 3.5602178912s 0.411779784s 3;
}

@keyframes drop-56 {
  25% {
    top: -7vh;
    left: -5vw;
    opacity: 1.4988367473;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-57 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(173.6666620481deg);
  animation: drop-57 3.6021294142s 0.2699623873s 3;
}

@keyframes drop-57 {
  25% {
    top: -4vh;
    left: -9vw;
    opacity: 1.4727970301;
  }
  100% {
    top: 50vh;
    left: -9vw;
    opacity: 0;
  }
}
.confetti-58 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(88.8254881391deg);
  animation: drop-58 3.0150765631s 0.9483500487s 3;
}

@keyframes drop-58 {
  25% {
    top: -2vh;
    left: 8vw;
    opacity: 1.0225442906;
  }
  100% {
    top: 50vh;
    left: 21vw;
    opacity: 0;
  }
}
.confetti-59 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(8.347424155deg);
  animation: drop-59 3.6981735741s 0.7245363063s 3;
}

@keyframes drop-59 {
  25% {
    top: -8vh;
    left: 14vw;
    opacity: 1.24487126;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-60 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(195.6108301984deg);
  animation: drop-60 3.3691136926s 0.4628318116s 3;
}

@keyframes drop-60 {
  25% {
    top: -2vh;
    left: -2vw;
    opacity: 1.1047450188;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-61 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(76.7829724608deg);
  animation: drop-61 3.6144992535s 0.6492229765s 3;
}

@keyframes drop-61 {
  25% {
    top: -25vh;
    left: 3vw;
    opacity: 0.5269258535;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-62 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(223.5048028898deg);
  animation: drop-62 3.7099757394s 0.605317437s 3;
}

@keyframes drop-62 {
  25% {
    top: -20vh;
    left: -1vw;
    opacity: 0.9571571066;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-63 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(141.2568185583deg);
  animation: drop-63 3.0482467158s 0.0856056799s 3;
}

@keyframes drop-63 {
  25% {
    top: -17vh;
    left: -1vw;
    opacity: 0.748958221;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-64 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(168.6675536883deg);
  animation: drop-64 3.7708209451s 0.2054271131s 3;
}

@keyframes drop-64 {
  25% {
    top: -14vh;
    left: -7vw;
    opacity: 1.1273079411;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-65 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(28.12333079deg);
  animation: drop-65 3.7272787469s 0.3450446061s 3;
}

@keyframes drop-65 {
  25% {
    top: -4vh;
    left: 2vw;
    opacity: 1.1232177269;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-66 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(144.6238884474deg);
  animation: drop-66 3.1801018678s 0.3075182468s 3;
}

@keyframes drop-66 {
  25% {
    top: -20vh;
    left: -8vw;
    opacity: 0.8592991404;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-67 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(349.9061349295deg);
  animation: drop-67 3.3536426041s 0.591386343s 3;
}

@keyframes drop-67 {
  25% {
    top: -22vh;
    left: 3vw;
    opacity: 1.3190015095;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-68 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(38.3642340365deg);
  animation: drop-68 3.0006099441s 0.9347341006s 3;
}

@keyframes drop-68 {
  25% {
    top: -8vh;
    left: 6vw;
    opacity: 1.222518726;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-69 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(66.918046698deg);
  animation: drop-69 3.5355437447s 0.7006502253s 3;
}

@keyframes drop-69 {
  25% {
    top: -22vh;
    left: 6vw;
    opacity: 1.1671254358;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-70 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(238.6169532869deg);
  animation: drop-70 3.277062766s 0.9675599453s 3;
}

@keyframes drop-70 {
  25% {
    top: -8vh;
    left: 8vw;
    opacity: 1.2347958342;
  }
  100% {
    top: 50vh;
    left: 22vw;
    opacity: 0;
  }
}
.confetti-71 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(117.7500209702deg);
  animation: drop-71 3.0548605883s 0.963192185s 3;
}

@keyframes drop-71 {
  25% {
    top: -9vh;
    left: 3vw;
    opacity: 1.3170426033;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-72 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(55.7026672474deg);
  animation: drop-72 3.3003745791s 0.9413113126s 3;
}

@keyframes drop-72 {
  25% {
    top: -11vh;
    left: 8vw;
    opacity: 0.5713743862;
  }
  100% {
    top: 50vh;
    left: 21vw;
    opacity: 0;
  }
}
.confetti-73 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(257.3320823533deg);
  animation: drop-73 3.907195474s 0.2412958606s 3;
}

@keyframes drop-73 {
  25% {
    top: -19vh;
    left: 14vw;
    opacity: 0.5273136245;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-74 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(27.2757586113deg);
  animation: drop-74 3.0493693484s 0.8695532984s 3;
}

@keyframes drop-74 {
  25% {
    top: -21vh;
    left: 11vw;
    opacity: 0.7859726292;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-75 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(154.8244428046deg);
  animation: drop-75 3.4571513744s 0.9469292059s 3;
}

@keyframes drop-75 {
  25% {
    top: -23vh;
    left: 13vw;
    opacity: 1.2615345456;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}

